<template>
  <portal to="body-footer">
    <div class="d-flex mt-2 pb-1 justify-content-between">
      <div
        class="d-flex"
        style="gap: 8px"
      >
        <b-button
          class="cancelBtn font-medium-1 font-weight-bolder"
          variant="outline-primary"
          @click="$router.push({name: 'home-orders-rental-sales-list'})"
        >
          {{ $t('Back to List') }}
        </b-button>
        <div
          v-for="button in leftSideButtons"
          :key="button.title"
        >
          <b-overlay
            v-if="isShowButton(button)"
            :show="loading.actionKey === button.actionKey && loading.progress"
            rounded
            opacity="0.6"
            spinner-small
            :spinner-variant="button.variant"
            class="d-inline-block"
          >
            <!--
              :disabled="loading.progress"-->
            <b-button
              :variant="button.variant"
              :class="button.classes"
              @click="submitAction(button.actionKey)"
            >
              <feather-icon
                :v-if="button.icon"
                :icon="button.icon ? button.icon.path : ''"
                :size="button.icon ? button.icon.size : ''"
              />
              {{ $t(button.title) }}
            </b-button>
          </b-overlay>
        </div>
      </div>
      <div
        class="d-flex"
        style="gap: 8px"
      >
        <div
          v-for="button in getRightButtons()"
          :key="button.title"
        >
          <b-overlay
            v-if="isShowButton(button)"
            :show="loading.actionKey === button.actionKey && loading.progress"
            rounded
            opacity="0.6"
            spinner-small
            :spinner-variant="button.variant"
            class="d-inline-block"
          >
            <!--
              :disabled="loading.progress"-->
            <b-button
              :variant="button.variant"
              :class="button.classes"
              @click="submitAction(button.actionKey)"
            >
              <feather-icon
                :v-if="button.icon"
                :icon="button.icon ? button.icon.path : ''"
                :size="button.icon ? button.icon.size : ''"
              />
              {{ $t(button.title) }}
            </b-button>
          </b-overlay>
        </div>

      </div>
    </div>
    <change-quote-modal
      ref="change-quote-modal"
      @submitOkEmit="submitChangeQuote()"
    />
    <release-asset-modal
      ref="release-asset-modal"
      @submitOkEmit="submitReleaseAssets()"
    />
  </portal>
</template>
<script>
import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import { BButton, BOverlay } from 'bootstrap-vue'
import html2canvas from 'html2canvas'
import ChangeQuoteModal from '@/views/main/orders/view/rental-sales/components/hold/ChangeQuoteModal.vue'
import ReleaseAssetModal from '@/views/main/orders/view/rental-sales/components/hold/ReleaseAssetModal.vue'
import { isNull } from 'lodash'
import config from './config'
import mainConfig from '../../config'

export default {
  name: 'QuoteFooterButtons',
  components: {
    ReleaseAssetModal,
    ChangeQuoteModal,
    BOverlay,
    BButton,
  },
  data() {
    return {
      loading: {
        actionKey: '',
        progress: false,
      },
    }
  },
  computed: {
    order() {
      return this.$store.state[this.MODULE_NAME].order
    },
    total_order_notes() {
      return this.$store.state[this.MODULE_NAME].totalsForm.total_order_notes
    },
    internal_shipping_notes() {
      return this.$store.state[this.MODULE_NAME].dispatchAndReturnForm.internal_shipping_notes
    },
    serviceOrderId() {
      return this.$store.state[this.MODULE_NAME].order.service_order_id
    },
    isVoid() {
      return this.$store.state[this.MODULE_NAME].order.status === 5
    },
  },
  methods: {
    isNull,
    isShowButton(b) {
      if (this.isVoid) {
        return b.isShowButtonIfVoidOrder
      }
      if (!isNull(this.serviceOrderId)) {
        return b.isShowButtonIfForServiceOrder
      }
      return true
    },
    onClear() {
      for (let i = 0; i < this.storeMutationsList.length; i++) {
        this.$store.commit(`${this.MODULE_NAME}/${this.storeMutationsList[i].mutation}`, this.storeMutationsList[i].payload)
      }
    },
    redirectToRFQSend(id, action) {
      if (id) {
        if (action === this.DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_CHANGE_QUOTE) {
          this.$router.push({
            name: 'draft-quote-information',
            params: { id },
          })
        } else if (action === this.DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_APPROVE) {
          this.$router.push({
            name: 'approve-quote-information',
            params: { id },
          })
        } else if (action === this.DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_VOID) {
          this.$router.push({
            name: 'home-orders-rental-sales',
          })
        } else if (action === this.DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_SEND_EMAIL) {
          window.location.reload()
        } else if (action === this.DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_RELEASE_ASSETS) {
          window.location.reload()
        } else {
          window.location.reload()
        }
      } else {
        this.$router.push({
          name: 'home-orders-rental-sales',
        })
      }
    },
    printCanvas() {
      const appContentEl = document.getElementById('app-content')

      html2canvas(appContentEl).then(canvas => {
        const printWindow = window.open('', '_blank')
        const printDocument = printWindow.document

        printDocument.write(`
        <html>
          <head>
            <title>Print Canvas</title>
            <style>
              body { margin: 0 15%;height: 100%; }
              canvas { height: 100%; width: 100%;}
            </style>
          </head>
          <body>
            <canvas id="printCanvas" style="display:block;"></canvas>
          </body>
        </html>
      `)

        const printCanvas = printDocument.getElementById('printCanvas')
        printCanvas.width = canvas.width
        printCanvas.height = canvas.height

        const printCtx = printCanvas.getContext('2d')
        printCtx.drawImage(canvas, 0, 0)

        printWindow.print()
        printWindow.close()
      })
    },
    submitAction(action) {
      const paramId = this.$route.params.id
      switch (action) {
        case this.DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_PRINT:
          this.printCanvas()
          break
        case this.DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_VOID:
          this.submitVoid(paramId).then(() => {
            this.redirectToRFQSend(paramId, action)
          })
          break
        case this.DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_RELEASE_ASSETS:
          this.submitReleaseAssets(paramId)
          break
        case this.DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_APPROVE:
          this.submitApprove(paramId).then(() => {
            this.redirectToRFQSend(paramId, action)
          })
          break
        case this.DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_SEND_EMAIL:
          this.submitSendByEmail(paramId).then(() => {
            this.redirectToRFQSend(paramId, action)
          })
          break
        case this.DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_SEND_AGAIN:
          this.submitEmailAgain(paramId).then(() => {
            this.redirectToRFQSend(paramId, action)
          })
          break
        case this.DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_CHANGE_QUOTE:
          this.showModelChangeQuote()
          break
        default:
          this.$toast.error('Unsupported action')
      }
    },

    submitVoid(id) {
      return this.$store.dispatch(`${this.MODULE_NAME}/voidOrder`, { id })
    },
    submitReleaseAssets(id) {
      this.$refs['release-asset-modal'].show()
    },
    submitSendByEmail(id) {
      return this.$store.dispatch(`${this.MODULE_NAME}/sendByEmail`, { id })
    },
    submitEmailAgain(id) {
      return this.$store.dispatch(`${this.MODULE_NAME}/emailAgain`, { id })
    },
    submitApprove(id) {
      return this.$store.dispatch(`${this.MODULE_NAME}/approve`, { id })
    },

    submitChangeQuote() {
      return this.$store.dispatch(`${this.MODULE_NAME}/changeQuote`, { id: this.order.id })
        .then(() => {
          this.$router.push({
            name: 'draft-quote-information',
            params: { id: this.order.id },
          })
        }).catch(err => {
          this.error = true
          this.errorNotification(this, err)
        })
    },
    showModelChangeQuote() {
      this.$refs['change-quote-modal'].show()
    },

    getStatusOrder() {
      return this.$store.state[this.MODULE_NAME]?.order?.status ?? 0
    },
    getStateOrder() {
      return this.$store.state[this.MODULE_NAME]?.order?.state ?? 0
    },
    getRightButtons() {
      return (this.getStatusOrder() === 4 && this.getStateOrder() === 1) ? this.rightSideHoldReadyButtons : this.rightSideHoldInProgressButtons
    },
  },
  setup() {
    const {
      EVENT_INFORMATION_TITLE,
      eventInformationObjectForRender,
    } = config()

    const {
      MODULE_NAME,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_CHANGE_QUOTE,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_SEND_EMAIL,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_SEND_AGAIN,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_VOID,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_RELEASE_ASSETS,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_APPROVE,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_PRINT,
      storeMutationsList,
      rightSideHoldInProgressButtons,
      rightSideHoldReadyButtons,
      leftSideButtons,
    } = mainConfig()

    return {
      MODULE_NAME,
      EVENT_INFORMATION_TITLE,
      getValueFromGivenObjectByKey,
      eventInformationObjectForRender,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_APPROVE,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_SEND_EMAIL,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_SEND_AGAIN,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_VOID,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_RELEASE_ASSETS,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_PRINT,
      rightSideHoldInProgressButtons,
      rightSideHoldReadyButtons,
      leftSideButtons,
      storeMutationsList,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_CHANGE_QUOTE,
    }
  },
}
</script>

<style scoped>
.bgRed{
  background-color: #6F1214 !important;
}
</style>
