<template>
  <div>
    <b-modal
      id="change-quote-model"
      centered
      hide-header
      body-class="change-quote-model-body py-2"
      footer-class="change-quote-model-footer"
      ok-variant="danger"
      ok-title="Proceed"
      modal-class="change-quote-model"
      cancel-variant="outline-primary"
      cancel-title="Cancel"
      size="lg"
      @hide="hide"
      @ok="submitOk"
    >
      <div>This action will un-hold all assets from the order. Do You want to proceed?</div>
    </b-modal>
  </div>
</template>
<script >

export default {
  name: 'ChangeQuoteModel',

  methods: {
    hide() { this.$bvModal.hide('change-quote-model') },
    show() { this.$bvModal.show('change-quote-model') },
    submitOk() {
      console.log('go-change-quote-page')
      this.$emit('submitOkEmit')
    },
    onModalHide() {
      this.hide()
    },
  },

}
</script>

<style scoped lang="scss">

</style>
