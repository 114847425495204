export default {
  GET(state, data) {
    state.order = { ...data }
  },
  LIST(state, data) {
    state.orders = data
  },
  LIST_ATTACHMENTS(state, data) {
    state.customerInformationForm.attachments = data
  },
  SET_ORDERS_FORM(state, data) {
    state.orderForm = { ...data }
  },
  SET_IS_SETTLED_CUSTOMER(state, bool) {
    state.isSettledCustomer = bool
  },
  SET_IS_ORDER_INFORMATION_FORM_REQUIRED_FIELDS_FILLED(state, bool) {
    state.isOrderInformationFormRequiredFieldsFilled = bool
  },
  SET_IS_EVENT_INFORMATION_REQUIRED_FIELDS_FILLED(state, bool) {
    state.isEventInformationRequiredFieldsFilled = bool
  },
  SET_IS_DISPATCH_AND_RETURN_ALL_REQUIRED_FIELDS_FILLED(state, bool) {
    state.isDispatchAndReturnAllRequiredFieldsFilled = bool
  },
  SET_ORDERS_INFORMATION_FORM(state, data) {
    state.orderInformationForm = data
  },
  SET_CUSTOMER_INFORMATION_FORM(state, data) {
    state.customerInformationForm = { ...data }
  },
  SET_CUSTOMER_INFORMATION_PURCHASE_ORDER(state, data) {
    state.requiresPurchaseOrder = data.requiresPurchaseOrder
  },
  SET_INSURANCE_INFORMATION_FORM(state, data) {
    state.insuranceInformationForm = { ...data }
  },
  SET_DISPATCH_AND_RETURN_FORM(state, data) {
    state.dispatchAndReturnForm = { ...data }
  },
  SET_EVENT_INFORMATION_FORM(state, data) {
    state.eventInformationForm = { ...data }
  },
  SET_ORDER_ITEMS_RENTAL_TABLE(state, data) {
    state.orderItemsRentalTable = data
  },
  // SET_ALLOW_TO_PICK_RENTAL_TIMES_BY_LINE_ITEM(state, bool) {
  //   state.allowToPickRentalTimesByLineItem = bool
  // },
  SET_ORDER_ITEMS_SALES_TABLE(state, data) {
    state.orderItemsSalesTable = data
  },
  SET_ORDER_ITEMS_OTHER_CHARGES_TABLE(state, data) {
    state.orderItemsOtherChargesTable = data
  },
  SET_TOTALS_FORM(state, data) {
    state.totalsForm = { ...data }
  },
  SET_ON_ERROR(state, data) {
    state.onError = { ...data }
  },
  SET_PAYER_ACCOUNT(state, data) {
    state.payerAccount = { ...data }
  },
  SET_GLOBAL_VARIABLE(state, value) {
    state.globalVariable = value
  },
}
