import { getValueFromGivenObjectByKey, formatNumberToCurrencyView } from '@core/utils/utils'
import { reactive } from '@vue/composition-api'
import {
  TEXT_INPUT,
} from '@/views/components/DynamicForm/constants'
import { format } from 'date-fns'

export default function () {
  const QUOTE_INFORMATION_TITLE = 'Quote Information'
  const EVENT_INFORMATION_TITLE = 'Event Information'
  const DISPATCH = 'Dispatch'
  const RETURN = 'Return'
  const RENTAL_ITEMS = 'Rental Items'
  const CAMERAS_LENSES = 'Cameras lenses'
  const LIGHTING = 'Lighting'
  const SALES = 'Sales'
  const OTHER_CHARGES = 'Other charges'
  const TOTALS = 'Totals'
  const INTERNAL_INFORMATION = 'Internal information'
  const ACTIVITY_LOG = 'Activity log'
  const ORDER_NOTES = 'Order notes'

  const quoteInformationObjectForRender = {
    Billing: [
      {
        label: 'DBA',
        key: data => getValueFromGivenObjectByKey(data, 'customer.cust_name_dba', '—'),
      },
      {
        label: 'Contact',
        key: data => getValueFromGivenObjectByKey(data, 'billing_contact.fullName', '—'),
      },
      {
        label: 'Phone',
        key: data => getValueFromGivenObjectByKey(data, 'billing_contact.phone_one', '—'),
      },
      {
        label: 'Email',
        key: data => getValueFromGivenObjectByKey(data, 'billing_contact.email', '—'),
      },
      {
        label: 'Address',
        key: data => getValueFromGivenObjectByKey(data, 'billing_address.street', '—'),
      },
    ],
    Dispatch: [
      {
        label: 'Contact',
        key: data => getValueFromGivenObjectByKey(data, 'dispatch_contact.fullName', '—'),
      },
      {
        label: 'Phone',
        key: data => getValueFromGivenObjectByKey(data, 'dispatch_contact.phone_one', '—'),
      },
      {
        label: 'Email',
        key: data => getValueFromGivenObjectByKey(data, 'dispatch_contact.email', '—'),
      },
    ],
    'Insurance Information': [
      {
        label: 'Policy No.',
        key: data => getValueFromGivenObjectByKey(data, 'insurance_policy.policy_number', '—'),
      },
      {
        label: 'For Up To',
        key: data => formatNumberToCurrencyView(getValueFromGivenObjectByKey(data, 'insurance_policy.amount_insured', '')),
      },
      {
        label: 'Deductible',
        key: data => formatNumberToCurrencyView(getValueFromGivenObjectByKey(data, 'insurance_policy.deductible', '')),
      },
    ],
    '': [
      {
        label: 'From',
        key: data => new Date(getValueFromGivenObjectByKey(data, 'event_information_dispatch_date', '') * 1000),
        type: 'date',
      },
      {
        label: 'To',
        key: data => new Date(getValueFromGivenObjectByKey(data, 'event_information_rental_end', '') * 1000),
        type: 'date',
      },
    ],
  }
  const eventInformationObjectForRender = [
    {
      key: 'reference',
      label: 'Event Referens',
    },
  ]
  const eventChildInformationObjectForRender = [
    {
      key: 'receive_method',
      label: 'Received Via',
    },
    {
      key: data => `${getValueFromGivenObjectByKey(data, 'order_placed_by.firstname', '—')} ${getValueFromGivenObjectByKey(data, 'order_placed_by.lastname', '—')}`,
      label: 'Placed By',
    },
    {
      key: data => {
        const val = getValueFromGivenObjectByKey(data, 'order_date', '')
        return val && new Date(val)
      },
      label: 'Placed On',
      type: 'date',
    },
  ]
  const dispatchObjectForRender = [
    {
      key: data => {
        const val = getValueFromGivenObjectByKey(data, 'event_information_dispatch_date', '')
        return val && new Date(val * 1000)
      },
      label: 'Pick up date',
      type: 'date',
    },
    {
      key: 'dispatch_method.name',
      label: 'Dispatch method',
    },
    {
      key: data => ` ${getValueFromGivenObjectByKey(data, 'dispatch_warehouse.label', '—')},
      ${getValueFromGivenObjectByKey(data, 'dispatch_address.street', '—')} 
      ${getValueFromGivenObjectByKey(data, 'dispatch_address.city', '')}
      ${getValueFromGivenObjectByKey(data, 'dispatch_address.zip', '')}
      `,
      label: 'Dispatch Address ',
    },
  ]
  const returnObjectForRender = [
    {
      key: data => {
        const val = getValueFromGivenObjectByKey(data, 'event_information_rental_end', '')
        return val && new Date(val * 1000)
      },
      label: 'Return date',
      type: 'date',
    },
    {
      key: 'return_method.name',
      label: 'Return method',
    },
    {
      key: data => ` ${getValueFromGivenObjectByKey(data, 'return_warehouse.label', '—')},
      ${getValueFromGivenObjectByKey(data, 'return_address.street', '—')} 
      ${getValueFromGivenObjectByKey(data, 'return_address.city', '')}
      ${getValueFromGivenObjectByKey(data, 'return_address.zip', '')}
      `,
      label: 'Return Address',
    },
  ]
  const tableColumnsCatalog = reactive([
    {
      key: 'sku',
      label: 'SKU',
      thStyle: { width: '15%' },
    },
    {
      key: 'name',
      label: 'Name',
      thStyle: { width: '43%' },
    },
    {
      key: 'amount',
      label: 'Units',
      thStyle: { width: '5%', padding: '5px 8px' },
    },
    {
      key: 'price',
      label: 'Rate',
      thStyle: { width: '5%', padding: '5px 8px' },
    },
    {
      key: 'subtotal',
      label: 'Subtotal',
      thStyle: { width: '5%', padding: '5px 8px' },
    },
    {
      key: 'taxes',
      label: 'Taxes',
      thStyle: { width: '5%', padding: '5px 8px' },
    },
    {
      key: 'totalPrice',
      label: 'TOTAL',
      thStyle: { width: '5%', padding: '5px 8px' },
    },
  ])
  const tableColumnsOtherCharges = reactive([
    {
      key: 'sku',
      label: 'SKU',
      thStyle: { width: '18%' },
    },
    {
      key: 'name',
      label: 'Name',
      thStyle: { width: '40%' },
    },
    {
      key: 'amount',
      label: 'Units',
      thStyle: { width: '5%', padding: '5px 8px' },
    },
    {
      key: 'price',
      label: 'Rate',
      thStyle: { width: '5%', padding: '5px 8px' },
    },
    {
      key: 'subtotal',
      label: 'Subtotal',
      thStyle: { width: '5%', padding: '5px 8px' },
    },
    {
      key: 'tax',
      label: 'Taxes',
      thStyle: { width: '5%', padding: '5px 8px' },
    },
    {
      key: 'totalPrice',
      label: 'TOTAL',
      thStyle: { width: '5%', padding: '5px 8px' },
    },
  ])
  const dispatchAndReturnFields = {
    internal_shipping_notes: {
      type: TEXT_INPUT,
      label: '',
      placeholder: 'Notes added',

    },
  }
  const productStatus = [
    {
      status: 8,
      icon: 'LTransferCalendarIcon',
      class: 'ORDERS_STATUS_IN_PROGRESS',
    },
    {
      status: 9,
      icon: 'LBlockIcon',
      class: 'ORDERS_STATUS_ATTENTION',
    },
    {
      status: 10,
      icon: 'LWarningIcon',
      class: 'ORDERS_STATUS_WARNING',
    },
    {
      status: 11,
      icon: 'LHoldIcon',
      class: 'ORDERS_STATUS_READY',
    },
  ]

  function fieldValue(data, item, formatDate) {
    const val = getValueFromGivenObjectByKey(data, item.key)
    // eslint-disable-next-line no-nested-ternary
    return item.type === 'date' ? val && val !== 'Invalid Date' ? format(val, formatDate) : '-' : val
  }

  return {
    EVENT_INFORMATION_TITLE,
    DISPATCH,
    RETURN,
    eventInformationObjectForRender,
    eventChildInformationObjectForRender,
    dispatchObjectForRender,
    returnObjectForRender,
    RENTAL_ITEMS,
    CAMERAS_LENSES,
    LIGHTING,
    SALES,
    OTHER_CHARGES,
    TOTALS,
    INTERNAL_INFORMATION,
    ACTIVITY_LOG,
    ORDER_NOTES,
    QUOTE_INFORMATION_TITLE,
    quoteInformationObjectForRender,
    tableColumnsCatalog,
    dispatchAndReturnFields,
    fieldValue,
    productStatus,
    tableColumnsOtherCharges,
  }
}
