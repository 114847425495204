<template>
  <div>
    <b-modal
      id="change-quote-model"
      centered
      hide-header
      body-class="change-quote-model-body py-2"
      footer-class="change-quote-model-footer"
      ok-variant="danger"
      ok-title="Proceed"
      modal-class="change-quote-model"
      cancel-variant="outline-primary"
      cancel-title="Cancel"
      size="lg"
      @hide="hide"
      @ok="submitOk"
    >
      <div>This action will un hold assets. Are you wish to continue?</div>
    </b-modal>
  </div>
</template>
<script>

export default {
  name: 'ReleaseAssetModal',

  methods: {
    hide() {
      this.$bvModal.hide('change-quote-model')
    },
    show() {
      this.$bvModal.show('change-quote-model')
    },
    submitOk() {
      this.$emit('submitOkEmit')
    },
    onModalHide() {
      this.hide()
    },
  },

}
</script>

<style scoped lang="scss">

</style>
