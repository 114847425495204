import crudActions from '@/libs/storeConstActions'
import axios from '@/libs/axios'
import store from '@/store'
import { stringify } from 'qs'

const endpoint = () => 'products'
const Actions = crudActions(endpoint)

const getSku = () => axios.get('/products/generate/sku')
const getComponents = (ctx, queryParams) => axios.get(`/${endpoint()}?type_id=2`, { params: queryParams, paramsSerializer: params => stringify(params) })
const getComponent = () => axios.get(`products/${store.state.catalogs?.catalog?.id}/components`)
const generateBarcode = () => axios.get(`/products/${store.state.catalogs?.catalog?.id}/generate/barcode`)

const stocks = (ctx, queryParams) => axios.get('/catalog/stocks', { params: queryParams, paramsSerializer: params => stringify(params) })
const stockActiveList = (ctx, queryParams) => {
  if (!queryParams.hasOwnProperty('f')) {
    queryParams = { ...queryParams, ...{ f: { is_active: 1 } } }
  }
  return axios.get('/catalog/stocks', { params: queryParams, paramsSerializer: params => stringify(params) })
}
const stockInActiveList = (ctx, queryParams) => axios.get('/catalog/stocks', { params: { ...queryParams, ...{ f: { is_active: 0 } } }, paramsSerializer: params => stringify(params) })

const nonStocks = (ctx, queryParams) => axios.get('/catalog/non-stocks', { params: queryParams, paramsSerializer: params => stringify(params) })
const nonStocksActiveList = (ctx, queryParams) => {
  if (!queryParams.hasOwnProperty('f')) {
    queryParams = { ...queryParams, ...{ f: { is_active: 1 } } }
  }
  return axios.get('/catalog/non-stocks', { params: queryParams, paramsSerializer: params => stringify(params) })
}
const nonStocksInActiveList = (ctx, queryParams) => axios.get('/catalog/non-stocks', { params: { ...queryParams, ...{ f: { is_active: 0 } } }, paramsSerializer: params => stringify(params) })
const getSubstitutes = (ctx, data) => axios.get(`/products/${store.state.catalogs?.catalog?.id}/substitutes`)

const exportCSV = () => axios.get(`${endpoint()}/csv-export`)
const importCSV = (ctx, data) => axios.post(`${endpoint()}/csv-import`, data.formData)
const exportCSVTemplate = () => axios.get(`${endpoint}/csv-template`)
const changeStatus = (ctx, data) => axios.post(`${endpoint()}/change-status`, data)
const brandAdd = (ctx, data) => axios.post('brands', data)
// const products = (ctx, queryParams) => axios.get('/catalog/group', { params: queryParams, paramsSerializer: params => stringify(params) })
const products = (ctx, queryParams) => axios.get('/catalog/group', { params: queryParams, paramsSerializer: params => stringify(params, { arrayFormat: 'brackets' }) })
// const products = (ctx, queryParams) => axios.get('/catalog/group', {
//   params: queryParams,
//   paramsSerializer: params => stringify(params, { arrayFormat: 'brackets' }),
// })
//   .then(response => {
//     ctx.commit('SET_PRODUCTS', response.data) // Store the data in Vuex state
//     return response
//   })
//   .catch(error => {
//     console.error(error)
//     throw error
//   })
export default {
  ...Actions,
  getSku,
  getComponents,
  getComponent,
  generateBarcode,
  getSubstitutes,
  stocks,
  brandAdd,
  nonStocks,
  exportCSV,
  importCSV,
  exportCSVTemplate,
  changeStatus,
  stockActiveList,
  stockInActiveList,
  nonStocksActiveList,
  nonStocksInActiveList,
  products,
}
