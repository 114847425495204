var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (Object.keys(_vm.rentalTableData).length)?_c('div',{staticClass:"mb-1"},[_c('h4',{staticClass:"font-weight-bolder mb-1"},[_vm._v(" "+_vm._s(_vm.$t(_vm.RENTAL_ITEMS))+" ")]),_vm._l((_vm.rentalTableData),function(ref){
var key = ref[0];
var value = ref[1];
var index = ref[2];
return _c('div',{key:index},[_c('h5',{staticClass:"font-weight-bolder "},[_vm._v(" "+_vm._s(value[0].category ? value[0].category.map(function (c) { return c.name; }).join(' + ') : '')+" ")]),_c('l-table-list-collector',{staticClass:"inventory-list-catalog-table",attrs:{"module-name":_vm.MODULE_NAME,"table-columns":_vm.tableColumnsCatalog,"is-searchable":false,"fetched-data":value,"has-footer":false},scopedSlots:_vm._u([{key:"cell(sku)",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"d-flex flex-column",staticStyle:{"max-height":"max-content"}},[_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(data.item.sku))]),_c('div',{staticClass:"position-relative",staticStyle:{"height":"30px","width":"450px"}},[_c('span',{staticClass:"ml-1 position-absolute item-note-input"},[_vm._v(_vm._s(data.item.notes))])])])]}},{key:"cell(name)",fn:function(ref){
var data = ref.data;
return [_c('div',[_c('feather-icon',{class:_vm.getClass(data),attrs:{"id":"ready","icon":_vm.getIcon(data),"size":"16"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(data.item.name))])],1)]}}],null,true)})],1)})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }